/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import AboutSima from "../../assets/images/About-sigma.png";
import Mission from "../../assets/images/mission image.svg";

export default function About() {
  return (
    <div className="about-bg">
      <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] mx-auto  ">
        <h2 className=" text-white text-xl sm:text-5xl f-f-b text-center py-10 hidden sm:block ">
          About Sigma Finance{" "}
        </h2>
        <h2 className=" text-white text-xl sm:text-5xl f-f-b text-center py-10  sm:hidden ">
          About
        </h2>
        <div className="grid grid-cols-12   gap-6 py-10  ">
          <div className="  col-span-12 lg:col-span-6   ">
            <img
              src={AboutSima}
              className=" w-[70%] h-auto sm:w-[504px] sm:h-[336px] ml-auto mr-auto xl:mr-0  "
            />
          </div>
          <div className="  col-span-12 lg:col-span-6 text-center lg:text-end   ">
            <h2 className=" text-white text-xl sm:text-4xl f-f-b  pt-10 ">
              About Sigma Finance
            </h2>
            <p className=" f-f-r sm:text-lg text-tiny text-white ">
              Sigma Finance is a Community driven passive income and asset
              management protocol that gives investors exclusive investment
              opportunities in the zksync ecosystem{" "}
            </p>
            <button className=" text-green f-f-r text-xl py-8 ">
              <ul className=" inline-flex  ">
                <li className=" mt-3 hidden sm:block ">
                  <svg
                    width="19"
                    height="14"
                    viewBox="0 0 19 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.07 0.782227L1 6.85223L7.07 12.9222M18 6.85223H1.17"
                      stroke="#51F585"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </li>
                <li className=" sm:ml-3 "><a href="https://sigma-finance.gitbook.io/sigma-finance-whitepaper/">Read More</a></li>
              </ul>
            </button>
          </div>
          {/* second grid started */}

          <div className="  col-span-12 lg:col-span-6   lg:hidden  ">
            <img
              src={Mission}
              className=" w-[50%] h-auto  ml-auto mr-auto xl:mr-0  "
            />
          </div>
          <div className="  col-span-12 lg:col-span-6 text-center lg:text-left   ">
            <h2 className=" text-white text-xl sm:text-4xl f-f-b  lg:pt-10 ">
              Our Mision
            </h2>
            <p className=" f-f-r sm:text-lg text-tiny text-white ">
              Our mission is to become an essential liquidity and founding center 
              for projects that want to launch on Zksync Era{" "}
            </p>
            <button className=" text-green f-f-r text-xl my-8 ">
              <ul className=" inline-flex  ">
                <li><a href="https://sigma-finance.gitbook.io/sigma-finance-whitepaper/">Read More</a></li>
                <li className=" mt-3 ml-3  hidden sm:block ">
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.43 1.38916L18.5 7.45916L12.43 13.5292M1.5 7.45916H18.33"
                      stroke="#51F585"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </li>
              </ul>
            </button>
          </div>
          <div className="  col-span-12 lg:col-span-6  hidden lg:block  ">
            <img
              src={Mission}
              className=" w-[61%] h-auto  ml-auto mr-auto xl:mr-0  "
            />
          </div>
          {/* second grid ended */}
        </div>
      </div>
    </div>
  );
}
