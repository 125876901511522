import React from "react";
import Yeld from "../../assets/images/yeld.svg";
import Early from "../../assets/images/Early.svg";
import invesment from "../../assets/images/invesment.svg";

export default function Activities() {
  return (
    <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] mx-auto py-20  ">
      <h1 className=" text-xl sm:text-5xl lg:text-6xl f-f-b text-white text-center ">
        Our <span className=" text-green "> Main </span> Activities
      </h1>
      <h3 className=" text-white text-lg sm:text-xl lg:text-5xl f-f-b text-center pt-20 ">
        Sigma Fund
      </h3>
      <div className="grid grid-cols-12   gap-6 py-16  ">
        <div className=" col-span-12  sm:col-span-6 md:col-span-4 xl:col-span-4  text-center  ">
          <h4 className=" text-green text-3xl md:text-4xl lg:text-5xl f-f-b ">
            30%
          </h4>
          <h5 className=" f-f-r  text-tiny md:text-base lg:text-lg text-white  ">
            
            Yield <br /> farming strategies
          </h5>
          <img
            src={Yeld}
            className=" h-auto w-[31%] sm:w-[35%] md:w-[40%] lg:w-[150px] lg:h-[166px] ml-auto mr-auto my-3 "
          />
        </div>
        <div className=" col-span-12  sm:col-span-6 md:col-span-4 xl:col-span-4  text-center  ">
          <h4 className=" text-green text-3xl md:text-4xl lg:text-5xl f-f-b ">
            40%
          </h4>
          <h5 className=" f-f-r  text-tiny md:text-base lg:text-lg text-white  ">
            
            early <br /> stage investing
          </h5>
          <img
            src={Early}
            className=" h-auto w-[40%] lg:w-[183px] lg:h-[186px] ml-auto mr-auto my-3 "
          />
        </div>
        <div className=" col-span-12  sm:col-span-6 md:col-span-4 xl:col-span-4  text-center  ">
          <h4 className=" text-green text-3xl md:text-4xl lg:text-5xl f-f-b ">
            30%
          </h4>
          <h5 className=" f-f-r  text-tiny md:text-base lg:text-lg text-white  ">
            
            investments <br />
            in zkSync bluechip
          </h5>
          <img
            src={invesment}
            className=" h-auto w-[40%]  lg:w-[183px] lg:h-[187px] ml-auto mr-auto my-3 "
          />
        </div>
      </div>
    </div>
  );
}
