import React from "react";
import ICON1 from "../../assets/images/ICON1.svg";
import ICON2 from "../../assets/images/ICON2.svg";
import ICON3 from "../../assets/images/ICON3.svg";
import ICON4 from "../../assets/images/ICON4.svg";

export default function Weare() {
  return (
    <>
      <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] mx-auto  ">
        <h2 class=" text-white text-xl sm:text-5xl f-f-b text-center pt-36 pb-10 ">
          Thats why <span className="text-green "> we are</span>{" "}
        </h2>
        <div className="grid grid-cols-12   gap-6  mt-14 md:mt-20 ">
          <div className="  col-span-12 md:col-span-6 xl:col-span-3   ">
            <img
              src={ICON1}
              className=" w-[122px] h-[122px] ml-auto mr-auto "
            />
            <div className=" wecrd my-2 text-center h-[254px] sm:h-[302px] md:h-[354px] xl:h-[529px] p-[11px] ">
              <h2 className=" f-f-r text-lg sm:text-xl text-white mt-5 ">
                Community driven
              </h2>
              <p className=" f-f-r  text-tiny sm:text-lg text-white mt-5 ">
                Sigma is a decentralized protocol. It is therefore the community
                that chooses the investments of the Sigma Fund and that proposes
                ideas to improve and grow the project.
              </p>
            </div>
          </div>
          {/*  crd started */}
          <div className="  col-span-12 md:col-span-6 xl:col-span-3   ">
            <img
              src={ICON2}
              className=" w-[122px] h-[122px] ml-auto mr-auto "
            />
            <div className=" wecrd my-2 text-center h-[254px] sm:h-[302px] md:h-[354px] xl:h-[529px] p-[11px] ">
              <h2 className=" f-f-r text-lg sm:text-xl text-white mt-5 ">
                Exclusive
              </h2>
              <p className=" f-f-r  text-tiny sm:text-lg text-white mt-5 ">
                Our goal is for our community fund to give you exclusive access
                to investment opportunities that are inaccessible to the
                majority of investors and originally reserved for institutions
                and private funds.
              </p>
            </div>
          </div>
          {/* crd ended */}

          {/*  crd started */}
          <div className="  col-span-12 md:col-span-6 xl:col-span-3   ">
            <img
              src={ICON3}
              className=" w-[122px] h-[122px] ml-auto mr-auto "
            />
            <div className=" wecrd my-2 text-center md:h-[461px] lg:h-[354px] xl:h-[529px] p-[11px] ">
              <h2 className=" f-f-r text-lg sm:text-xl text-white mt-5 ">
                Safe and Innovating
              </h2>
              <p className=" f-f-r  text-tiny sm:text-lg text-white mt-5 ">
                In addition to early stage investment, our strategy is 50%
                diversification through investment in bluechips in the ZK
                ecosystem. Our team is also working on creating a launchpad to
                diversify the protocol's revenue streams, reduce its overall
                risk and improve its long-term performance.
              </p>
            </div>
          </div>
          {/* crd ended */}
          {/*  crd started */}
          <div className="  col-span-12 md:col-span-6 xl:col-span-3   ">
            <img
              src={ICON4}
              className=" w-[122px] h-[122px] ml-auto mr-auto "
            />
            <div className=" wecrd my-2 text-center md:h-[461px] lg:h-[354px] xl:h-[529px] p-[11px] ">
              <h2 className=" f-f-r text-lg sm:text-xl text-white mt-5 ">
                Revenue Sharing
              </h2>
              <p className=" f-f-r  text-tiny sm:text-lg text-white mt-5 ">
                Sigma aims to provide its users with a sustainable yield so that
                the protocol can last over time. The protocol therefore
                allocates 50% of its total revenue to users via the reward pool
                (revenue is generated via transaction fees, Sigma Fund profits
                and Launchpad revenue).
              </p>
            </div>
          </div>
          {/* crd ended */}
        </div>
        <div className="text-center py-20">
          <a href="https://sigma-finance.gitbook.io/sigma-finance-whitepaper/" target="_blank" rel="noreferrer">
          <button
            type="button"
            className="text-white   w-[328px] h-[56px] sm:h-[68px] rounded-[48px]  bg-tranparent border border-green text-tiny sm:text-xl  f-f-r  text-center  "
          >
            Read documentation
          </button>
          </a>
        </div>
      </div>
    </>
  );
}
