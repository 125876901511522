/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import zksync from "../../assets/images/zksync.svg";
import howsky from "../../assets/images/howsky.svg";
import launpadsky from "../../assets/images/launpadsky.svg";

export default function Blog() {
  return (
    <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] mx-auto pb-20  ">
      <h3 class=" text-white text-xl lg:text-5xl f-f-b text-center  ">
        Recent Blog Posts
      </h3>
      <div className="border border-grey mt-20  "></div>
      <ul className=" w-full sm:inline-flex  ">
        <li className=" w-full">
          <a href="https://medium.com/@Sigma-Finance/comprehensive-guide-for-public-presale-round-and-launch-tokenomics-e211d742ae73" target="_blank" rel="noreferrer">
            <h4 className=" text-white text-lg sm:text-xl f-f-m  mt-16  text-center sm:text-left ">
              Public Presale Round and Launch Tokenomics
            </h4>
          </a>
        </li>
        <li>
          <img
            src={zksync}
            className=" w-[146px] h-[90px] my-8 ml-auto mr-auto "
          />
        </li>
      </ul>
      <div className="border border-grey  "></div>
      <ul className=" w-full sm:inline-flex  ">
        
          <li className=" w-full">
            <a href="https://medium.com/@Sigma-Finance/what-is-sigma-finance-d3506f01b08e" target="_blank" rel="noreferrer">
              <h4 className=" text-white text-lg sm:text-xl f-f-m  mt-16  text-center sm:text-left ">
                What is Sigma Finance ?
              </h4>
            </a>
          </li>
        
        <li>
          <img
            src={howsky}
            className=" w-[146px] h-[90px] my-8 ml-auto mr-auto "
          />
        </li>
      </ul>
      <div className="border border-grey  "></div>
      <ul className=" w-full sm:inline-flex  ">
        <li className=" w-full">
          <a href="https://medium.com/@Sigma-Finance/what-is-coming-after-sigma-launchpad-b4c176bb76f2" target="_blank" rel="noreferrer">
            <h4 className=" text-white text-lg sm:text-xl f-f-m  mt-16  text-center sm:text-left ">
              What is coming after $Sigma launchpad?
            </h4>
          </a>
        </li>
        <li>
          <img
            src={launpadsky}
            className=" w-[146px] h-[90px] my-8 ml-auto mr-auto "
          />
        </li>
      </ul>
    </div>
  );
}
