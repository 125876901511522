import React from "react";
import Launchpadimg from "../../assets/images/Launchpad Images.svg";

export default function Launchpad() {
  return (
    <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] mx-auto py-20  ">
      <h2 className=" text-xl sm:text-5xl lg:text-6xl f-f-b text-white text-center ">
        {" "}
        Launchpad{" "}
      </h2>
      <img
        src={Launchpadimg}
        className=" w-[90%] ml-auto mr-auto h-auto py-10 "
      />
      <div className=" w-10/12 lg:w-9/12 mx-auto py-5 ">
        <p className=" text-white f-f-m text-center text-lg sm:text-2xl lg:text-3xl ">
          Sigma Finance aims to launch zkSync’s most promising projects
        </p>
      </div>
    </div>
  );
}
