import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import About from "./About";
import Weare from "./Weare";
import Activities from "./Activities";
import Launchpad from "./Launchpad";
import Roadmap from "./Roadmap";
import Blog from "./Blog";
import Footer from "./Footer";

export default function index() {
  return (
    <div className=" home-bg ">
      <Header />
      <Hero />
      <About />
      <Weare />
      <Activities />
      <Launchpad />
      <div id="roadmap" >
      <Roadmap />
      </div>
      <Blog />
      <Footer />
    </div>
  );
}
