/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TWITTER from "../../assets/images/TWITTER MASK GROUP.svg";
import dots from "../../assets/images/dots.svg";
import TELEGRAM from "../../assets/images/TELEGRAM.svg";

export default function Footer() {
  return (
    <div className=" bg-primary py-20 ">
      <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] mx-auto   ">
        <ul className=" inline-flex  w-full justify-center sm:justify-start ">
          <li>
            <a href="https://twitter.com/Sigma_Fund" target="_blank" rel="noreferrer">
            <img src={TWITTER} className=" w-[36px] h-[63px] cursor-pointer " />
            </a>
          </li>
          <li className=" ml-10">
          <a href="https://medium.com/@Sigma-Finance"target="_blank" rel="noreferrer" >
            <img src={dots} className=" w-[36px] h-[63px]  cursor-pointer" />
            </a>
          </li>
          <li className=" ml-10">
          <a href="#" target="_blank" rel="noreferrer">
            <img
              src={TELEGRAM}
              className=" w-[36px] h-[63px] cursor-pointer "
            />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
