/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import roadmap from "../../assets/images/roadmap.svg";

export default function Roadmap() {
  return (
    <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] mx-auto py-20  ">
      <h2 className=" text-xl sm:text-5xl lg:text-6xl f-f-b text-green text-center ">
        {" "}
        Road<span className="text-white">map</span>
      </h2>
      <img src={roadmap} className=" w-[85%] ml-auto mr-auto py-20 " />
      <div class="text-center py-14">
        <a href="https://sigma-finance.gitbook.io/sigma-finance-whitepaper/" target="_blank" rel="noreferrer">
        <button
          type="button"
          className="text-white   w-[328px] h-[56px] sm:h-[68px] rounded-[48px]  bg-tranparent border border-green text-tiny sm:text-xl  f-f-r  text-center  "
        >
          Read documentation
        </button>
        </a>
      </div>
    </div>
  );
}
