/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import VAULT from "../../assets/images/VAULT.png";

export default function Hero() {
  return (
    <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] mx-auto  ">
      <div className="grid grid-cols-12   gap-6 lg:gap-0  ">
        <div className="  col-span-12 lg:col-span-5  block lg:hidden ">
          <div className="  lg:mt-0 lg:pt-10 xl:pt-16 ">
            <img
              src={VAULT}
              className="w-[85%] sm:w-[66%] ml-auto mr-auto h-auto object-contain  "
            />
          </div>
        </div>
        <div className="  col-span-12 lg:col-span-7 ">
          <div className=" lg:pt-32  text-center lg:text-left ">
            <h2 className=" text-2xl sm:text-6xl xl:text-7xl text-white f-f-b  ">
              Sigma Finance
            </h2>
            <h3 className=" f-f-m text-lg sm:text-3xl xl:text-4xl text-white ">
              The Zksync Era liquidity hub{" "}
            </h3>
            <h4 className=" text-white  text-tiny sm:text-lg xl:text-xl f-f-r ">
              Generate sustainable yield & invest like a Venture Capitalist
            </h4>
            <ul className=" my-14 xl:my-20  sm:inline-flex ">
              <li>
                <button
                  type="button"
                  class="text-black   w-[185px] h-[55px]  bg-green text-lg  f-f-b  text-center  "
                  onClick={() => {
                    window.open("https://app-sigma-finance.io", "_blank");
                  }}
                >
                  Enter IDO !
                </button>
              </li>
              <li className=" mt-10 sm:mt-0 sm:ml-7 ">
                <button
                  type="button"
                  class="text-white   w-[185px] h-[55px]  bg-tranparent border border-green text-lg  f-f-b  text-center  "
                >
                  Coming soon !
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="  col-span-12 lg:col-span-5 hidden lg:block ">
          <div className=" -mt-20 lg:mt-0 lg:pt-10 xl:pt-16 ">
            <img
              src={VAULT}
              className=" w-full h-auto lg:h-[535px] xl:h-[587px] object-contain  "
            />
          </div>
        </div>
      </div>
      <h1></h1>
    </div>
  );
}
