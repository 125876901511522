/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Logo from "../../assets/images/LOGO.svg";

export default function ButtonAppBar() {
  const [navbar, setnavbar] = useState(false);
  return (
    <>
      <nav className=" bg-transparent  w-full z-20 top-0 left-0  py-5 ">
        <div className=" w-11/12 xl:max-w-[1156px] 2xl:max-w-[1502px] flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="" className="flex items-center outline-none ">
            <img src={Logo} className="w-[128px] h-[36px] " alt="" />
          </a>
          <div className="flex md:order-2">
            <button
              type="button"
              className="text-black   w-[120px] h-[37px] lg:w-[129px] lg:h-[41px]  bg-green   lg:text-base  text-tiny  f-f-b  text-center hidden sm:block  "
              onClick={() => {
                window.open("https://app-sigma-finance.io", "_blank");
              }}
            >
            Launch App
            </button>
            <button
              onClick={() => {
                setnavbar(!navbar);
              }}
              data-collapse-toggle="navbar-sticky"
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden outline-none "
              aria-controls="navbar-sticky"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="#FFFFFF"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between ${
              navbar ? "block" : "hidden"
            }  w-full md:flex md:w-auto md:order-1`}
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg  md:flex-row md:space-x-6 lg:space-x-12 xl:space-x-14 md:mt-0 md:border-0 ">
              <li>
                <a
                  href="#"
                  className="block py-2 pl-3 pr-4 hover:text-black md:hover:text-green text-white f-f-m   lg:text-base  text-tiny   hover:bg-green rounded md:bg-transparent md:hover:bg-transparent  md:p-0 "
                  aria-current="page"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#ComingSoon"
                  className="block py-2 pl-3 pr-4 hover:text-black md:hover:text-green text-white f-f-m   lg:text-base  text-tiny  hover:bg-green  rounded  md:hover:bg-transparent  md:p-0 "
                >
                  Vaults
                </a>
              </li>
              <li>
                <a
                  href="#roadmap"
                  className="block py-2 pl-3 pr-4 hover:text-black md:hover:text-green text-white f-f-m   lg:text-base  text-tiny  hover:bg-green  rounded  md:hover:bg-transparent  md:p-0 "
                >
                  Roadmap
                </a>
              </li>
              <li>
                <a
                  href="https://sigma-finance.gitbook.io/sigma-finance-whitepaper/" target="_blank" rel="noreferrer"
                  className="block py-2 pl-3 pr-4 hover:text-black md:hover:text-green text-white f-f-m   lg:text-base  text-tiny  hover:bg-green  rounded  md:hover:bg-transparent  md:p-0 "
                >
                  Docs
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 pl-3 pr-4 sm:hidden  text-black f-f-m   lg:text-base  text-tiny  bg-green  rounded   md:p-0 "
                >
                  launch App
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
